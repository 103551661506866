import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "@emotion/styled";
import etonLogo from "../img/eton.png";

library.add(faUser);

const Grid = styled.div`
  grid-area: kicksve;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;
  grid-template-areas:
    "pic"
    "text";

  @media (min-width: 1200px) {
    display: grid;
    align-items: left;
    justify-items: left;
    grid-auto-columns: 1fr;
    grid-template-areas: "pic text";
  }
`;

const Text = styled.div`
  grid-area: text;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;

  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 120px;
    min-width: 780px;
  }
`;

const PictureContainer = styled.div`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Picture = styled.img`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Wrapper = styled.div`
  padding-bottom: 80px;
  background: #0a7887;
  background: linear-gradient(90deg, #234279 0%, #0f254c 100%);
  opacity: 0.96;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  padding-top: 54px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
`;

const FullWidthText = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 28px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const ColumnText = styled.div`
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  padding: 0px 10px;

  @media (min-width: 1200px) {
    width: 50%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Inner = styled.div`
  justify-content: center;
  padding: 0px 10px;

  @media (min-width: 1200px) {
    max-width: 700px;
    margin-top: 40px;
    padding: 0px 50px;
  }
`;

const SingleTextLeft = styled(Inner)`
  @media (min-width: 1200px) {
    float: right;
  }
`;

const SingleTextRight = styled(Inner)`
  @media (min-width: 1200px) {
    float: left;
    padding-bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const AboutKickSve = () => {
  return (
    <section id="about">
      <Wrapper>
        <Title>Om KicSve</Title>

        <FullWidthText>
          <ColumnText>
            <SingleTextLeft>
              <p>
                Jag brinner för att med kundglasögonen på, se och utveckla nya
                möjligheter runt hörnet!
              </p>
              <p>
                Med mina +20 års erfarenhet från entreprenörsbolag med stora
                tillväxtresor kan jag, med ett affärsmässigt helhetsperspektiv
                och intresse av hela affären från A till Ö, bistå med en
                flexibel verktygslåda.
              </p>
              <p>
                Genom åren har jag samlat såväl strategisk som operativ
                erfarenhet av strategi & mål, inköp & försäljning, analys och
                allokering från Retail och E-Commerce inom detaljhandeln. Med
                ett agilt förhållningssätt och stort kundfokus, strävar jag både
                efter att optimera nuvarande och utveckla nya relevanta
                kunderbjudanden som skapar försäljningspotential, ekonomisk
                tillväxt och ökad kundnöjdhet.
              </p>
            </SingleTextLeft>
          </ColumnText>
          <ColumnText>
            <SingleTextRight>
              <p>
                Erfarenheter som nu även senast har kompletterats med utbildning
                inom hållbarhetsområdet och hur som företag möta en allt
                tydligare konsumentdriven hållbarhetsutveckling inom e-handel
                och logistik. Verktygen och inblicken i processer för att med
                innovation, transparens och kunskap kan ligga steget före i att
                ledsaga konsumenterna till de mer hållbara valen och bidra till
                ett förändrat hållbarhetsbeteende lokalt och globalt.
              </p>
              <p>
                Nu är jag nyfiken på att tillsammans i nya uppdrag och projekt
                driva hållbar utveckling och digital transformation inom
                detaljhandeln. Som strategisk eller operativ konsult kan jag
                snabbt vara insatt och med flexibilitet i behov av kompetens,
                tid och omfång bidra med nytta och att VI tillsammans skapar
                resultat.
              </p>
              {/*<ImageContainer>
                <img src={etonLogo} alt="logo" style={{ maxWidth: "200px" }} />
              </ImageContainer>*/}
              <p>Ser fram mot nya möten och möjligheter runt hörnet!</p>
              <p>Kicki Svensson</p>
            </SingleTextRight>
          </ColumnText>
        </FullWidthText>
      </Wrapper>
    </section>
  );
};

/* const AboutKickSve = () =>  {
    return (
      <section id="about" className="about-mf sect-pt4 route about-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="skill-mf">
                    <h5 className="title-left">Verktygslåda</h5>
                    <ul style={{ marginTop: 55 }}>
                      <li>Strategi & Mål</li>
                      <li> Digital Strategi </li>
                      <li>Inköps- & Försäljningsbudget & Prognos</li>
                      <li>Analys & Digital Analys</li>
                      <li>Sortimentsstrategi & Sortimentsplanering</li>
                      <li>Merchandising online & butik</li>
                      <li>Inköpsplanering & Lagerstyrning</li>
                      <li>Varustyrning</li>
                      <li>System- & Verksamhetsutveckling</li>
                      <li>Agila processer & förhållningssätt</li>
                      <li>Digital transformation</li>
                      <li>Förändringsledarskap & Projektledning</li>
                      <li>
                        Excel, Google Analytics, QlickView, Power BI, DaVinci,
                        JDA, IFS, SAP, Magento
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5 className="title-left">Om mig</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
} */

export default AboutKickSve;
