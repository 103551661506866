import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "@emotion/styled";
// import myImage from "../img/myImage.png";
import road from "../img/road.jpg";

library.add(faUser);

const Grid = styled.div`
  grid-area: kicksve;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;
  grid-template-areas:
    "pic"
    "text";

  @media (min-width: 1200px) {
    display: grid;
    align-items: left;
    justify-items: left;
    grid-auto-columns: 1fr;
    grid-template-areas: "pic text";
  }
`;

const Text = styled.div`
  grid-area: text;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;

  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 120px;
  }
`;

const PictureContainer = styled.div`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Picture = styled.img`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Wrapper = styled.div`
  padding-bottom: 50px;
  background-color: #2f5597;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 700;
  padding-top: 54px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #2f5597;
`;

const SideTitle = styled.div`
  font-size: 45px;
  font-weight: 700;
  padding-top: 54px;
  line-height: 1em;
  color: black;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: white;
`;

const FullWidthText = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 28px;
  width: 100%;
`;

const SingleTextLeft = styled.div`
  max-width: 600px;
  padding-bottom: 50px;
  float: left;
`;

const IntroText = styled.p`
  text-align: center;
  font-style: bold;
`;

const H2 = styled.h2`
  font-weight: 700;
  font-size: 28px;
`;

const Vision = () => {
  return (
    <section id="vision">
      <Grid>
        <PictureContainer>
          <Picture src={road} alt="logo" />
        </PictureContainer>

        <Text>
          <SideTitle>Vision</SideTitle>

          <IntroText>
            Jag tror starkt på och brinner för samarbeten där VI tillsammans gör
            varandra bra och har stor gemensam nyfikenhet på att driva
            innovation, resultat och hållbar utveckling.
            <p>Mitt bidrag till ett gemensamt VI är:</p>
          </IntroText>
          <H2>Mixen av specialist & generalist</H2>
          <ul style={{ marginTop: 10 }}>
            <li>Många kombinationsmöjligheter av kompetenser</li>
            <li>Flexibilitet i stort som smått, uppstart och avslut</li>
            <li>Operativ & strategisk</li>
            <li>Analytiskt och datadrivna beslut</li>
            <li>Intresse för produkten och affären bakom siffrorna</li>
          </ul>
          <H2>Fokus på helhetsperspektivet</H2>
          <ul style={{ marginTop: 10 }}>
            <li>Kundglasögonen är alltid på!</li>
            <li>Brinner för försäljningsoptimering genom Unified Commerce</li>
            <li>Affärsmässigt helhetsperspektiv</li>
            <li>Ekonomiskt, Socialt och Ekologiskt hållbarhetsperspektiv</li>
          </ul>
          <H2>Väcka nyfikenhet & engagemang</H2>
          <ul style={{ marginTop: 10 }}>
            <li>Personligt och passionerat</li>
            <li>Nyfikenhet till innovation</li>
            <li>Positivt – allt är möjligt!</li>
            <li>Lära av varandra och kompetensutveckling i vardagen</li>
          </ul>
          <H2>Utveckla samarbeten</H2>
          <ul style={{ marginTop: 10 }}>
            <li>Agila processer och stöd över avdelningsgränser</li>
            <li>Tillsammans med branschkunniga konsulter</li>
            <li>Breddad portfölj av erbjudande och kompetens</li>
            <li>
              Utveckla gemensamma rutiner och etablera hållbara samarbeten
            </li>
          </ul>
        </Text>
      </Grid>
    </section>
  );
};

export default Vision;
