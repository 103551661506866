import styled from "@emotion/styled";
import React from "react";
import Typed from "react-typed";
import imageOverlay from "../img/kicsve-black2-cropped.jpeg";

const TableCell = styled.div`
  display: table-cell;
  vertical-align: bottom;
  margin-bottom: 100px;

  @media (min-width: 800px) {
    vertical-align: middle;
    margin-bottom: 0px;
  }
`;

const TypedDesktop = styled(Typed)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const TypedMobile = styled(Typed)`
  @media (min-width: 800px) {
    display: none;
  }
`;

const Container = styled.div`
  @media (max-width: 800px) {
    margin-bottom: 100px;
  }
`;

class Intro extends React.Component {
  render() {
    return (
      // <div id="home" className="intro route bg-image " style={{backgroundImage: "url("+bigImage+")"}}>
      <div id="home" className="intro route bg-image background">
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />

        <div
          className="intro-content display-table bg-image"
          style={{ backgroundImage: "url(" + imageOverlay + ")" }}
        >
          <TableCell>
            <Container className="container">
              <TypedMobile
                strings={[
                  "Engagemang",
                  "Nyfikenhet",
                  "Samarbete",
                  "Tillsammans",
                ]}
                typeSpeed={80}
                backDelay={1100}
                backSpeed={30}
                loop
              />
              <h1 className="intro-title mb-4">
                En unik kombination <br />
                av specialiserad generalist
              </h1>
              <p className="intro-subtitle">
                <span className="text-slider-items"></span>
                <strong className="text-slider">
                  <TypedDesktop
                    strings={[
                      "Engagemang",
                      "Nyfikenhet",
                      "Samarbete",
                      "Tillsammans",
                    ]}
                    typeSpeed={80}
                    backDelay={1100}
                    backSpeed={30}
                    loop
                  />
                </strong>
              </p>
            </Container>
          </TableCell>
        </div>
      </div>
    );
  }
}

export default Intro;
