import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUniversity,
  faChartBar,
  faGlobeEurope,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "@emotion/styled";
import sweden from "../img/sweden.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Separator from "./separator";

library.add([faUniversity, faChartBar, faGlobeEurope, faRulerCombined]);

const offerings = [
  {
    title: "Strategisk & Operativ Analys",
    description: (
      <>
        <p>Jag brinner för analys och försäljningspotential!</p>
        <p>
          Med mångårig erfarenhet av att leda budget- och prognosarbete har jag
          ett affärsmässigt brett helhetsperspektiv. Jag har möjlighet att
          utifrån strategier och mål kunna bistå med stöd att ta vid eller
          utveckla nuvarande systemstöd och rutiner för analys och rapportering.
        </p>
        <p>
          Digital analys och insikter är mitt A & O genom processen och
          produkters livscykel. Med ett stort kundfokus och ett 360 tänk brinner
          jag för potentialerna och möjligheterna till agerande i alla kanaler
          med såväl kort- som långsiktigt mätbart resultat.
        </p>
        <p>
          Som konsult kan jag både bidra som analytiker och specialist i teamet
          eller med ledarskap där tydliga strategier och mål ger teamet
          förutsättningar för agilt agerande och tillsammans utveckla
          affärsmannaskapet och resultatfokus.
        </p>
        {/*<ul style={{ marginTop: 10, textAlign: "left" }}>
          <li>Strategi & Mål</li>
          <li>E-Commerce & Digital Strategi</li>
          <li>Analys & Rapportering</li>
          <li>Försäljningsbudget & Prognos</li>
          <li>Inköpsbudget</li>
          <li>Excel, Google Analytics, QlickView, Power BI</li>
      </ul>*/}
      </>
    ),
    icon: "chart-bar",
  },
  {
    title: "Sortimentsplanering & Allokering",
    description: (
      <>
        <p>Jag brinner för ”rätt vara på rätt plats i rätt tid”!</p>
        <p>
          Med kundglasögonen på ser jag till möjligheterna i hur man kan
          utveckla sortiment och planera erbjudande som skapar mervärde. Med ett
          stort intresse för produkten och ett ständigt nytänkande i hur man
          utvecklar aktuella och relevanta kunderbjudanden, ”see now buy now”,
          kan jag bistå med såväl ekonomisk sortimentsstrategi och uppföljning
          som sortimentsplanering och kvantifiering.
        </p>
        <p>
          Efter många års samarbete med inköp, logistik och butiksorganisation
          finns också en bred erfarenhet och operativ kunskap i att utveckla
          systemstöd för sortimentsplanering och allokering – allt från Excel
          till integrerade affärssystem.
        </p>
        {/*<ul style={{ marginTop: 10, textAlign: "left" }}>
          <li>Sortimentsstrategi</li>
          <li>Sortimentsplanering</li>
          <li>Kampanjplan</li>
          <li>Inköpsplanering & Förpackningsoptimering</li>
          <li>Allokering & Lageroptimering</li>
          <li>Merchandising butik & online</li>
          <li>Excel, DaVinci, JDA, IFS, SAP, Magento</li>
        </ul>*/}
      </>
    ),
    icon: "ruler-combined",
  },
  {
    title: "Hållbar affärsutveckling",
    description: (
      <>
        <p>
          Jag brinner för hållbara processer och enkelhet i överlämning av
          stafettpinnen!
        </p>

        <p>
          Jag tror på affärs- och verksamhetsutveckling som möjliggör
          effektivare och roligare samarbeten där alla känner delaktighet och
          har insikt i det egna bidraget till helheten. Med erfarenhet från
          flera stora systemutvecklingsprojekt driver jag fokus på
          användarenkelhet samt verksamhetsstöd som möjliggör agila
          förhållningssätt - en nyckel för digital transformation.
        </p>
        <p>
          Med ny kunskap och intresse för konsumentdriven hållbarhetsutveckling
          samt bred erfarenhet från konsumenthandel, ser jag fram mot att bistå
          i innovationsprojekt kring social, ekonomisk och ekologisk hållbarhet.
          För att bidra och leda kunden till mer hållbara val genom hela
          köpresan, ser jag fram emot att vägleda och inspirera företag att vara
          transparenta gällande allt från strategier till hållbara produkter.
          Allas vår strävan i att göra skillnad och bidra till ett förändrat
          hållbarhetsbeteende, både i ett lokalt och globalt perspektiv.
        </p>
        {/*<ul style={{ marginTop: 10, textAlign: "left" }}>
          <li>Digital transformation</li>
          <li>Affärsplan</li>
          <li>Affärsutveckling</li>
          <li>Verksamhetsutveckling</li>
          <li>Konsumentdriven hållbarhetsutvecklin</li>
          <li>Hållbarhetsutveckling inom E-handel & Logistik</li>
          <li>Förändringsledarskap & Projektledning</li>
        </ul>*/}
      </>
    ),
    icon: "globe-europe",
  },

  {
    title: "Utbildning",
    description: (
      <>
        <p>Jag brinner för att dela med mig av kunskap och erfarenhet! </p>
        <p>
          Med en bred bakgrund från detaljhandeln inom Retail & E-Commerce och
          delaktighet i flera stora utvecklingsprojekt, finns erfarenhet och
          bakgrund att leda utbildningar av specifika roller, områden hos
          företag eller yrkesutbildningar. Jag tror på att utifrån fokusområdet
          i utbildningen, bredda med nuläge och relatera till omkringliggande
          processer och på så sätt skapa bredare kunskap, affärsmässigt
          helhetsperspektiv och insikt för det egna bidraget inom företaget.
        </p>
      </>
    ),
    icon: "university",
  },
];

const OffersContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  height: 100%;
  font-weight: 400;
  font-size: 20px;
  color: white;
  line-height: 28px;
  display: grid;
  z-index: 10;
  justify-content: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  @media (min-width: 1350px) {
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    :nth-child(4n + 1) {
      justify-self: flex-start;
    }

    :nth-child(4n + 2) {
      justify-self: center;
    }

    :nth-child(4n + 3 {
      justify-self: center;
    }

    :nth-child(4n + 4) {
      justify-self: flex-end;
    }
  }
`;

const Container = styled.div`
  position: relative;
  width: calc(100% - 1px);
  background-size: cover;
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  left: 0;
  height: 2900px;

  @media (min-width: 600px) {
    height: 2750px;
  }

  @media (min-width: 768px) {
    height: 1700px;
  }

  @media (min-width: 1350px) {
    height: 1100px;
  }

  @media (min-width: 1500px) {
    height: 975px;
  }
`;

const ImageBackgroundContainer = styled.div`
  width: 100.1%;
  height: 100.1%;
  position: absolute;
  overflow: hidden;
  z-index: 2;
`;

const ImagePlaceholder = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  visibility: visible;
`;

const Image = styled.div`
  background-image: url(${sweden});
  background-position: center center;
  background-repeat: no-repeat;
  transform: translate3d(0px, 159.156px, 0px) scale(1.005, 1.005);
  height: 800px;
  top: auto;
  bottom: 0;
  background-attachment: scroll;
  background-size: cover;
  position: absolute;
  z-index: 1;
  width: 100%;
`;

const Gradient = styled.div`
  background: #0a7887;
  background: linear-gradient(90deg, #234279 0%, #0f254c 100%);
  opacity: 0.96;
  width: 100%;
  height: 100%;
  z-index: 2;
  backface-visibility: hidden;
  position: absolute;
`;

const Offers = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  padding-bottom: 45px;
  position: relative;
  z-index: 1;
  overflow: visible;
  background: #132a54;

  :before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-1.8deg);
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  padding-top: 40px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
`;

const Section = styled.section``;

const Offerings = () => {
  return (
    <>
      <Separator title={"Erbjudande"} />
      <Section id="offerings">
        <div>
          <Container>
            <ImageBackgroundContainer>
              <ImagePlaceholder>
                <Image />
              </ImagePlaceholder>
              <Gradient />
            </ImageBackgroundContainer>
            <Offers>
              <OffersContainer>
                {offerings.map((x) => (
                  <Offer
                    id={x.title}
                    key={x.description}
                    description={x.description}
                    title={x.title}
                    icon={x.icon}
                  />
                ))}
              </OffersContainer>
            </Offers>
          </Container>
        </div>
      </Section>
    </>
  );
};

const OfferWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 4%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const OfferTitle = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  margin: 30px 0;
`;

const OfferDescription = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 16px;
`;
const OfferIcon = styled.div`
  width: 100%;
  text-align: center;
  font-size: 40px;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Offer = ({ title, description, icon }) => {
  return (
    <OfferWrapper>
      <div className="body" style={{ width: "100%" }}>
        <div>
          <OfferIcon>
            <FontAwesomeIcon icon={["fas", icon]} />
          </OfferIcon>
          <OfferTitle>{title}</OfferTitle>
          <OfferDescription>{description}</OfferDescription>
        </div>
      </div>
    </OfferWrapper>
  );
};

export default Offerings;
